
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input'],
  setup(props, ctx) {
    const autoWidth = computed({
      get: () => {
        return props.value
      },
      set: (val) => {
        ctx.emit('input', val)
      }
    })

    return { autoWidth }
  }
})

