
import { reactive, defineComponent, toRefs, computed } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: String,
      default: ''
    }

  },
  emits: ['changeinput'],
  setup(props, ctx) {
    const dataMap = reactive({
      options: [
        'xlsx', 'csv', 'txt'
      ]
    })

    const bookType = computed({
      get: () => {
        return props.value
      },
      set: (value) => {
        ctx.emit('changeinput', value)
      }
    })

    const booktypeChange = (value: any) => {
      console.log(value)
      ctx.emit('changeinput', value)
    }
    return { ...toRefs(dataMap), bookType, booktypeChange }
  }
})
